import React from 'react';
import { GatsbyImage }from 'gatsby-plugin-image';

import Text from 'components/Text';
import Button from 'components/Button';
import EducationCard from 'components/Bio/EducationCard';
// import SubscriptionForm from 'components/Subscribe';
import * as styles from './styles.module.scss';

export default class IndexPage extends React.Component {
  render() {
    return (
      <div className={styles.root}>
        <Text>
          <h1>Margaret Cerjan</h1>

          <GatsbyImage  
            className="img circle left"
            image={this.props.headerImage}
            alt={"Maggie Cerjan"}
          />

          <p className="intro right">
            Nominated a “Citizen Musician” by the Chicago Symphony Orchestra, Maggie Cerjan has
            established herself as a committed and passionate educator over the past decade.
            Currently the director of
            the <a href="http://www.bu.edu/cfa/tanglewood/program/junior-strings-intensive/" target="_blank" rel="noopener noreferrer">
            Junior Strings Intensive</a> at the Boston University Tanglewood Institute and a faculty member
            at <a href="https://necmusic.edu/prep" target="_blank" rel="noopener noreferrer">New England Conservatory's Preparatory School</a>,
            Maggie is the founder and director of Stonybrook Strings.
          </p>

          <p>
            Maggie has enjoyed a full and varied career as an educator-performer. After
            graduating with a Bachelor's in Violin Performance from Northwestern University, she
            joined the Civic Orchestra of Chicago, and became a faculty member at the YOURS Project,
            an orchestral program based on Venezuela’s El Sistema, that teaches students leadership,
            responsibility, and teamwork through music. Inspired and motivated by her violin
            students’ progress in one year from performing simple beginner songs to Beethoven’s
            Fifth Symphony, Maggie dedicated herself fully to teaching and began training with
            with some of the country’s leading pedagogues. She studied
            the <a href="https://suzukiassociation.org/about/suzuki-method/" target="_blank" rel="noopener noreferrer">
            Suzuki teaching method</a> at the Chicago Suzuki Institute with trainer Ann Montzka
            Smelser, and at Ithaca College with Stevie Sandven and Ed Kreitman, and completed
            her <a href="http://aosa.org/about/what-is-orff-schulwerk/" target="_blank" rel="noopener noreferrer">
            Orff-Shulwerk</a> Certification at George Mason University with Donna Fleetwood
            and Matt McCoy.
          </p>

          <p>
            Maggie coached the Chicago Symphony Youth Orchestras and performed over 40 concerts
            a year at public schools and community centers as a teaching artist through the Chicago
            Symphony’s Institute for Learning Access and Training. As a founding member of the
            Chicago-based Élan String Quartet, Maggie was invited to perform at the Utah
            Symphony’s Emerging Quartets’ program, collaborated with the Chicago Symphony’s
            Institute and Chicago Young Authors to create beat poetry and classical music concerts
            with poets from Englewood High School, and performed the Schubert Cello Quintet with
            Yo-Yo Ma at the Chicago Symphony’s Citizen Musician Week.
          </p>

          <p>
            Maggie moved to Boston to complete her Master of Music degree at Boston University.
            During this time, she served as a faculty member
            at <a href="http://www.tchs.org/" target="_blank" rel="noopener noreferrer">The Chestnut Hill School</a>, Dana
            Hall School, and Conservatory Charter Lab, in addition to maintaining a studio of
            talented private students and performing widely. Believing deeply that true musicianship is a
            holistic process that benefits from inspiration and collaboration, Maggie’s students
            have gained access to youth orchestra programs and summer festivals, created
            multi-subject performance art and digital music, explored the scientific principles of
            music, and incorporated mind-body practices into their playing.
          </p>

          <p>
            In 2018, Maggie founded Stonybrook Strings to educate young musicians to perform
            beautifully, lead confidently, and build community through music.
          </p>
        </Text>

        <EducationCard />

        <div className={styles.contact}>
          <h5>Get in touch with Maggie today</h5>
          <Button to="/contact/" text="Enroll" />
        </div>

        {/*<SubscriptionForm text="Receive updates from Stonybrook Strings:" />*/}
      </div>
    );
  }
}
