import React from 'react';
import * as styles from './styles.module.scss';

export default class IndexPage extends React.Component {
  render() {
    return (
      <div className={styles.root}>
        <div>
          <h6 className={styles.header}>Education</h6>
          <ul>
            <li>
              <span>Master of Music, Violin Performance</span>
              <span>Boston University</span>
            </li>
            <li>
              <span>Bachelor of Music, Violin Performance</span>
              <span>Northwestern University</span>
            </li>
          </ul>
        </div>
        <div>
          <h6 className={styles.header}>Teacher Certifications</h6>
          <ul>
            <li>
              <span>Orff Schulwerk Levels I, II, and III</span>
              <span>George Mason University</span>
            </li>
            <li>
              <span>Suzuki Violin Training Books 2 and 3</span>
              <span>Ithaca College</span>
            </li>
            <li>
              <span>Suzuki Violin Training Book 1</span>
              <span>Chicago Suzuki Institute</span>
            </li>
            <li>
              <span>Music Mind Games, Level I</span>
            </li>
          </ul>
        </div>
        <div />
      </div>
    );
  }
}
