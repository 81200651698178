import React from 'react';
import { graphql } from 'gatsby'
import Bio from 'components/Bio';
import Layout from 'components/Layout'

export default class IndexPage extends React.Component {
  render() {
    return <Layout
      location={this.props.location}
      title={"Stonybrook Strings | Meet Margaret Cerjan"}
      description={"Meet Margaret Cerjan, the founder and director of Stonybrook Strings"}
    >
      <Bio headerImage={this.props.data.headerImage.childImageSharp.gatsbyImageData} />
    </Layout>;
  }
}

export const query = graphql`
  query MaggieQuery {
    headerImage: file(relativePath: { eq: "portrait1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout:FIXED, width: 200, height: 200)
      }
    }
  }
`;
