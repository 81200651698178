import React from 'react';
import * as styles from './styles.module.scss';

export default class Text extends React.Component {
  render() {
    const classList = [styles.root];

    this.props.className && classList.push(this.props.className);

    return <div className={classList.join(' ')}>{this.props.children}</div>;
  }
}
